<template>
  <v-dialog v-model="value" persistent max-width="550px">
    <v-form ref="form" v-model="valid">
      <v-card>
        <v-card-title class="bg-gradient-primary-2">
          <span class="white--text">Download Ratified Approval</span>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-row>
            <v-col>
              <ac-select
                label="User"
                v-model="userData"
                :items="companyUser"
                class="company-select"
                multiple
                chips
                clearable
              />
            </v-col>
            <v-col>
              <ac-select
                label="Year "
                v-model="currentYear"
                :items="years"
                :retain-focus="false"
                class="company-select"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              Please select the transaction month to download the ratification for that transaction
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <ac-select
                label="From Month"
                v-model="fromMonth"
                :items="allMonths"
                :retain-focus="false"
                class="company-select"
              />
            </v-col>
            <v-col cols="6">
              <ac-select
                label="To Month "
                v-model="toMonth"
                :items="allMonths"
                :retain-focus="false"
                class="company-select"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <ac-button title="Cancel" color="error" outlined @click="onClose" />
          <ac-button title="Download" color="success" :loading="loading" @click="onSubmit" />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { emailValidator, required } from '@core/utils/validation'
import moment from 'moment'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'DownloadApprovalModal',
  components: {
    AcInput: () => import('@/components/AcInput'),
    AcButton: () => import('@/components/AcButton'),
    AcSelect: () => import('@/components/AcSelect'),
    AcComboBox: () => import('@/components/AcComboBox.vue'),
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    companyUser: {
      type: Array,
    },
  },

  data() {
    return {
      userData: null,

      loading: false,
      valid: false,
      validators: {
        required,
        emailValidator,
      },
      // * form
      allMonths: [],
      toMonth: '',
      fromMonth: '',
      years: [],
      currentYear: '',
    }
  },

  computed: {
    ...mapGetters('app', ['selectedCompany']),
  },
  async created() {
    let startYear = 2017
    let currentYear = new Date().getFullYear()
    this.years = [{ id: 'All', name: 'All' }]
    for (let year = currentYear; year >= startYear; year--) {
      this.years.push({ id: year.toString(), name: year.toString() })
    }
    let monthData = moment.monthsShort()
    monthData.forEach((element, index) => {
      this.allMonths.push({
        id: index,
        name: element,
      })
    })
  },
  methods: {
    ...mapActions('transactionStaff', ['downloadStaff']),

    async onSubmit() {
      let userList = this.userData.join(',')
      const payload = {
        year: this.currentYear,
        users: userList,
        startMonth: this.fromMonth,
        endMonth: this.toMonth,
        company: this.selectedCompany,
      }

      const { success, message } = await this.downloadStaff(payload)
      if (success) {
        this.onClose()
        this.AcNotify({
          type: 'success',
          message,
          position: 'bottom',
        })
      } else {
        this.AcNotify({
          type: 'error',
          message,
          position: 'bottom',
        })
      }
    },
    onClose(refetch) {
      if (refetch) {
        const { selectedCompany } = this
        this.$emit('onClose', { selectedCompany })
      }

      this.reset()
      this.$emit('input', false)
    },

    reset() {
      this.licenses = []
      this.$refs.form.reset()
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped></style>
